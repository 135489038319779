'use strict';

Gri.module({
  name: 'modal-beta',
  ieVersion: null,
  $el: $('.modal-beta'),
  container: '.modal-beta',
  fn: function () {
    const items = this.$el.find('.content .items');
    items.owlCarousel({
      singleItem: true,
      items: 1,
      dots: false,
      nav: true,
      navText: ["<i class='icon-left-arrow'>", "<i class='icon-right-arrow'>"]
    }).on('changed.owl.carousel', function (event) {
      let index = event.item.index;
      const activeItem = items.find('.item').eq(index);
      items.find('[data-html]').html('');
      activeItem.find('[data-html]').html(activeItem.find('[data-html]').data('html'));
    });

    this.$el.find('.bg, .close-btn').on('click', function () {
      $('.modal-beta').removeClass('active');
      items.find('[data-html]').html('');
    });
  }
});
