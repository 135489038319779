'use strict';

Gri.module({
  name: 'carousel-gamma',
  ieVersion: null,
  $el: $('.carousel-gamma'),
  container: '.carousel-gamma',
  fn: function () {
    this.$el.owlCarousel({
      center: true,
      loop: true,
      // autoplay:true,
      // autoplayTimeout:3000,
      items: 1,
      margin:20,
      nav:true,
      navText: ["<i class='icon-left-arrow'>", "<i class='icon-right-arrow'>"],
      responsive:{
        600:{
          items:1
        }
      }
    });
  }
});
