'use strict';

Gri.module({
  name: 'sercu-carousel',
  ieVersion: null,
  $el: $('.sercu-carousel'),
  container: '.sercu-carousel',
  fn: function () {
    sercuu();
     $(window).resize(function () {
       sercuu();
     });
    function sercuu() {
      const media = window.matchMedia( "(max-width: 992px)" );
      if(media.matches){
        $('.sercu-carousel').addClass("owl-carousel").owlCarousel({
          dots:true,
          nav:false,
          items:1
        });
      }else{
        $('.sercu-carousel').removeClass('owl-carousel');
      }
    }
  }
});

