'use strict';

Gri.module({
  name: 'box-beta',
  ieVersion: null,
  $el: $('.box-beta'),
  container: '.box-beta',
  fn: function () {


    // var pLength = 0;
    // $(".box-beta .info-content").each(function( index ) {
    //   pLength = 0;
    //   $(this).find(" > *").each(function( index2 ) {
    //     if($(this).is("p")) {
    //       if(pLength > 0) {
    //         $(this).remove();
    //       } else {
    //         if($(this).text() == "") {
    //           $(this).remove();
    //         } else {
    //           pLength++;
    //         }
    //       }
    //     }
    //     if($(this).is("img")) {
    //       $(this).remove();
    //     }
    //     if($(this).is("br")) {
    //       $(this).remove();
    //     }
    //   });
    // });


    // $('.box-beta .info-img a').on('click', function () {
    //   const modal = $($(this).data('modal'));
    //   console.log(modal.length);
    //   modal.addClass('active');
    //   let index = $(this).data('index');
    //   const activeItem = modal.find('.item').eq(index);
    //   activeItem.find('[data-html]').html(activeItem.find('[data-html]').data('html'));
    //   modal.find('.content .items').trigger('to.owl.carousel', index);
    // });

    var maxheight = 0;
    var maxheight2 = 0;

    setTimeout(function () {
      $('.box-beta').each(function () {
        if (maxheight < $(this).height()) {
          maxheight = $(this).height();
        }
      });
      $('.box-beta').height(maxheight);

      $('.box-beta h2').each(function () {
        if (maxheight2 < $(this).height()) {
          maxheight2 = $(this).height();
        }
      });
      $('.box-beta h2').height(maxheight2);
    }, 200);

  }
});
